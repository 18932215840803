<template>
  <b-row>
    <b-col cols="12" md="10">
      <b-card class="job-post-wrapper">
        <h3>{{ jobPost.title }}</h3>
        <p class="text-dark">{{ getValueFromSource(jobPost, 'company.company_name') }} <span class="text-muted">. {{ getValueFromSource(jobPost, 'company.company_address') }} {{ formatDateDistance(jobPost.created) }}</span></p>

        <div class="">
          <div class="d-flex align-items-center mb-05">
            <p class="mr-1 m-0"><feather-icon icon="BriefcaseIcon" size="20" /></p>
            <p class="m-0">{{ getValueFromSource(jobPost, 'job_type') }}</p>
          </div>

          <div class="d-flex align-items-center mb-05">
            <p class="mr-1 m-0"><feather-icon icon="MapPinIcon" size="20" /></p>
            <p class="m-0">{{ getValueFromSource(jobPost, 'work_place_type') }}</p>
          </div>

          <div class="d-flex align-items-center">
            <p class="mr-1 m-0"><feather-icon icon="ListIcon" size="20" /></p>
            <p class="m-0">{{ getValueFromSource(jobPost, 'job_interests').join(', ') }}</p>
          </div>

          <b-alert :show="!isLoggedIn" variant="danger" class="p-2 mt-2">
            <router-link :to="{ name: 'auth-login' }" class="underline" style="cursor: pointer">
              Login to apply for this job
            </router-link>
          </b-alert>
          <b-alert :show="isCurrentUserJobSeeker && has_applied" variant="success" class="p-2 mt-2">You have applied for this job.</b-alert>
          <b-button
            v-if="isCurrentUserJobSeeker && canApplyForJob"
            :disabled="has_applied"
            variant="primary"
            class="my-2 w-25"
            @click="onApply()"
          >
            Apply
          </b-button>
          <b-alert v-else :show="isCurrentUserJobSeeker && !canApplyForJob" variant="danger" class="p-2 mt-2">
            CV Score of at least 50 is required to apply for jobs
          </b-alert>
        </div>

        <div class="mt-2">
          <hr />
          <h3>About the job</h3>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="job-details" v-html="getValueFromSource(jobPost, 'content')"></div>
        </div>

        <div class="mt-2 pt-2">
          <hr />
          <h3>About the company</h3>

          <div class="d-flex align-items-center">
            <b-avatar
              size="lg"
              :src="getValueFromSource(jobPost, 'company.logo.path')"
              class="mr-1"
            />

            <div>
              <p class="m-0">{{ getValueFromSource(jobPost, 'company.company_name') }}</p>
              <p class="m-0">{{ getValueFromSource(jobPost, 'company.company_address') }}</p>
            </div>
          </div>

          <p class="text-muted mt-2">{{ getValueFromSource(jobPost, 'company.company_description') }}</p>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { get } from "lodash";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BCard, BAvatar, BMedia, BBadge, BAlert } from "bootstrap-vue";

import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAlert,
    BModal,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    jobPost: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      has_applied: false
    }
  },
  computed: {
    canApplyForJob() {
      return this.getValueFromSource(this.currentUser, 'cv_score', 0) >= 50
    }
  },
  created() {
    if (this.isCurrentUserJobSeeker){
      this.checkForApplication();
    }
  },
  methods: {
    async onApply(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Request?',
          text: "Do you want to apply for this job?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().clientService.applyJob(this.jobPost._id);
        await this.checkForApplication()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Application sent successfully'
          },
        });
      } catch (error) {
        console.log("error", error, error.response.data.message)
        const error_message = get(error, "response.data.message") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async checkForApplication(){
      try {
        this.loading = true;
        const has_applied_req = await this.useJwt().clientService.checkForApplication(this.jobPost._id);
        const { data } = has_applied_req.data;
        this.has_applied = data.status
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
.job-post-wrapper {
  p {
   font-size: 12px;
  }
  .mb-05 {
    margin-bottom: 5px;
  }
  .job-details * {
    font-size: 14px;
  }
}
</style>

